<template>
  <div 
    v-if="question" 
    class="content"
    :style="{backgroundImage: `url(/maps/${scenario.mapImage})`}"
  >
    <div class="page_content brand-font-bold">
      <div class="page_content--wrap">
        <div class="page_content--question">
          <h1 class="page_content--question-num">
            Question {{ $route.params.qid }}
          </h1>
          {{ question.text }}
        </div>
        <p class="content-overlay__subtext">
          <span aria-label="Having made your choice take time to consider the following hint. Select your answer again.">
            Having made your choice take time to consider the following hint. Select your answer again.
          </span>
        </p>
        <div
          v-if="question.showHint"
          class="page_content--hint"
        >
          <div>
            <h1>Hint!</h1>
            <p>Consider time of day, location, weather and status of the patient.<br><br>You can go back to see this information.</p>
          </div>
        </div>
        <div
          class="page_content--answers"
          :class="{'answered': (answeredCorrectly !== null)}"
        >
          <div 
            v-for="(answer, index) in question.answers" 
            :key="index"
            :class="{'page_content--answers--group-wrap': answer.group}"
          >
            <p
              v-if="answer.group"
              class="page_content--answers--group"
            >
              <strong v-if="index > 1">
                OR
              </strong>

              <span v-html="answer.group" />
            </p>
            <div
              v-else
              :class="{'correct': (answer.correct && answeredCorrectly !== null), 'selected': selectedAnswer === index}"
            >
              <div class="page_content--answers--indicator">
                <Check v-if="answer.correct" />
                <Cross v-else />
              </div>
              <button
                class="brand-font-bold"
                role="button"
                @click="answeredCorrectly === null && validate(index)"
              >
                <span>{{ answer.text }}</span>
                <small>{{ answer.time }}</small>
              </button>
            </div>
          </div>
        </div>

        <div class="page_content--response">
          <template v-if="answeredCorrectly !== null && question.answeredText">
            <div v-if="answeredCorrectly">
              Correct!
            </div>
            <div v-else>
              Incorrect...
            </div>
            {{ question.answeredText }}
          </template>
        </div>
      </div>
    </div>

    <Incident
      v-if="question.showIncident"
      class="incident-icon"
      :style="{top: scenario.incidentLocation.top, left: scenario.incidentLocation.left}"
    />
    <Hospital
      v-for="(location, index) in scenario.hospitalLocations"
      :key="index"
      class="hospital-icon"
      :style="{top: location.top, left: location.left}"
    />
    <Base
      v-if="question.baseLocation"
      class="base-icon"
      :style="{top: question.baseLocation.top, left: question.baseLocation.left}"
    />
    
    <nav
      class="page-navigation"
      role="navigation"
    >
      <div>
        <router-link 
          v-if="backLink" 
          :to="backLink"
          role="button"
        >
          <div>
            <ArrowLeft />
            <div>Back</div>
          </div>
        </router-link>
      </div>

      <div>
        <router-link 
          v-if="nextLink" 
          :to="nextLink"
          role="button"
        >
          <div>
            <ArrowRight />
            <div>Next</div>
          </div>
        </router-link>
      </div>
    </nav>
  
    <div
      class="text-toggle"
      @click="increaseFontSize"
    >
      <IncreaseFontSize />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ArrowRight from '@/components/icons/ArrowRight.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import Cross from '@/components/icons/Cross.vue'
import Check from '@/components/icons/Check.vue'
import Hospital from '@/components/icons/Hospital.vue'
import Incident from '@/components/icons/Incident.vue'
import Base from '@/components/icons/Base.vue'
import IncreaseFontSize from "@/components/icons/IncreaseFontSize";

export default {
  name: 'Question',
  components: {
    IncreaseFontSize,
    ArrowRight,
    ArrowLeft,
    Cross,
    Check,
    Hospital,
    Incident,
    Base
  },
  data() {
    // Configure the defaults for the data
    // answeredCorrectly: null if not answered yet, false if incorrect, true if correct
    // selectedAnswer: null if nothing selected yet, then set to the ID/index of the answer
    // scenario: the current scenario
    // questions: the questions for this scenario
    // question: the current question
    return {
        answeredCorrectly: null,
        selectedAnswer: null,
        scenario: null,
        questions: [],
        question: null
    } 
  },
  computed: {
    backLink() {
      return `/scenario/${this.$route.params.id}/question/${this.$route.params.qid }`
    },
    nextLink() {
      // Generate the "next" link, get the next ID
      let nextId = parseInt(this.$route.params.qid) + 1

      // If the question is answered then...
      if (this.answeredCorrectly !== null) {
        // If the next question doesn't exist in the scenario then send to the end
        if (typeof this.questions[nextId - 1] === 'undefined') {
          return `/scenario/${this.$route.params.id}/complete`
        } else {
          // Otherwise send to the next question
          return `/scenario/${this.$route.params.id}/question/${nextId}`
        }
      }

      // Null until the question is answered
      return null
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.qid) {
          this.fetchData()
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  methods: {
    validate(selected) {
      // Check if the answer is correct
      
      // Store the selected answer
      this.selectedAnswer = selected

      // Generate the percentage value per question
      let scoreValue = 100 / this.questions.length

      // Check if the answer is correct
      this.answeredCorrectly = this.question.answers[selected].correct

      // If the answer is incorrect set score value to zero
      scoreValue = this.answeredCorrectly ? scoreValue : 0

      // Adjust the score for this scenario/question combination
      this.$store.commit('adjustScore', {index: this.$route.params.id + '_' + this.$route.params.qid, score: scoreValue})
    },
    async fetchData() {
      // Reset the scenario data whilst it's loading
      this.question = null
      this.scenario = null
      this.questions = []

      // Import the scenarion JSON file
      await import('@/content/scenarios/scenario-' + this.$route.params.id + '.json').then((content) => {
        // Get the questions
        const questions = this.questions = content.default.questions

        // Get the question index we're looking for 
        const questionIndex = parseInt(this.$route.params.qid) - 1

        // Check if the question for this index exists, if not redirect to home
        if (typeof questions[questionIndex] === 'undefined') {
          this.$router.push('/')
        } else {
          // Assign the scenario and question
          this.scenario = content.default
          this.question = questions[questionIndex]
        }
      }).catch(() => {
        // If there's any issue opening the JSON file then redirect to home
        this.$router.push('/')
      })
    },
    increaseFontSize() {
      if (document.body.classList.contains('increased-font-size')) {
        // Do something
        document.body.classList.remove('increased-font-size')
      } else {
        document.body.classList.add('increased-font-size')
      }
    },
  }
}
</script>

<style scoped>
.page_content {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
}

.text-toggle {
  position: absolute;
  bottom: 100px;
  right: 100px;
  background: #FEC000;
  border-radius: 50%;
  padding: 58px;
  cursor: pointer;
  width: 150px;
  height: 150px;
  z-index: 9999;
}

.text-toggle svg {
  width: 150px;
}

.increased-font-size .content-overlay {
  top: 1em;
  width: 60% !important;
  font-size: 90px !important;
}

.increased-font-size .page_content--response > div.page_content--hint p,
.increased-font-size .page_content--answers button,
.increased-font-size .page_content--answers button small,
.increased-font-size .page_content--question-num,
.increased-font-size .page_content--response {
  font-size: 90px !important;
}

.incident-icon, .hospital-icon, .base-icon {
  position: absolute;
  width: 238px;
  height: auto;
}

.page_content--wrap {
  padding: 12%;
  margin-top: auto;
  margin-bottom: auto;
}

.page_content--question-num {
  font-size: 81px;
  margin-bottom: 1em;
}

.page_content--question {
  font-size: 150px;
  line-height: 1;
  margin-bottom: .75em;
}

.page_content--answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page_content--answers button {
  background: #E1001E;
  font-size: inherit;
  color: #fff;
  padding: .2em .9em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: inherit;
  text-align: center;
}

.page_content--answers button * {
  flex-basis: 100%;
}

.page_content--answers > div {
    flex: 1 1 45%;
    max-width: 50%;
}

.page_content--answers > div > div {
  font-size: 100px;
  border-radius: 2em;
  border: .2em solid transparent;
  padding: .2em;
  position: relative;
  margin-top: .4em;
  width:  100%;
  box-sizing: border-box;
}

.page_content--answers--group {
  font-size: 81px;
  line-height: 1.5;
  margin: 0;
}

.page_content--answers--group-wrap {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.page_content--answers--group strong {
  display: block;
  font-size: 100px;
  margin-top: .5em;
}

.page_content--answers .selected {
    border-color: #FEC000;
}

.page_content--answers--indicator {
  display: none;
  position: absolute;
  top: .4em;
  left: .4em;
  transform: translate(-50%, -50%);
  background: #BC011A;
  height: 150px;
  border-radius: 50%;
  width: 150px;
  padding: .1em 0;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.page_content--answers--indicator svg {
  width: 40%;
  height: auto;
}

.page_content--answers .correct .page_content--answers--indicator {
  background-color: #FEC000;
}

.page_content--answers.answered button small {
  display: block;
}

.page_content--answers.answered .page_content--answers--indicator {
  display: flex;
}

.page_content--answers button small {
  font-size: .65em;
  vertical-align: middle;
}

.page_content--response {
  font-size: 100px;
}

.page_content--response > div {
  font-size: 150px;
  margin-top: 1em;
  color: #FEC000;
  margin-bottom: .35em;
}

.content {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(48,66,81,.9);
  top: 0;
  left: 0;
}

.page-navigation {
  position: absolute;
  z-index: 999;
  top: 70%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.page-navigation > div {
  overflow:  hidden;
  pointer-events: all;
}

.page-navigation > div:first-of-type a {
  right: auto;
  left: -50%;
  text-align: right;
}

.page-navigation > div:first-of-type a svg {
  right: 15px;
  left: auto;
}

.page-navigation a {
  background: #FEC000;
  height: 350px;
  width: 350px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  right: -50%;
  text-decoration: none;
}

.page-navigation a > div {
  font-size: 50px;
  color: #E1001E;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  left: .75em;
  text-transform: uppercase;
}

.page-navigation > div:first-of-type a > div {
  right: .75em;
  left:  auto;
}

.page-navigation a > div > div {
  margin-top: .25em;
}

.page-navigation a svg {
  width: 1.75em;
  height: auto;
  position: relative;
  left: .25em;
}

div.page_content--hint {
  background: #FEC000;
  border-radius: .5em;
  color: #304251;
  padding:  .25em;
  font-size: 150px;
  margin-top: 1em;
  margin-bottom: 0.35em;
}

div.page_content--hint p {
  font-size: 80px;
  margin: 0;
  margin-top: .5em;
}

div.page_content--hint h1 {
  color: #E1001E;
  font-size: 120px;
  margin-bottom: 0;
}

div.page_content--hint > div {
  border: 8px solid #304251;
  border-radius: .5em;
  padding: .5em 2em;
}
</style>
