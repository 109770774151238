<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.081"
    height="32.081"
    viewBox="0 0 32.081 32.081"
  >
    <title>Incorrect</title>
    <g transform="translate(0.707 0.707)"><g
      transform="translate(0 26.286) rotate(-45)"
      fill="#fff"
      stroke="#fff"
      stroke-width="0.5"
    ><rect
      width="37.174"
      height="6.196"
      stroke="none"
    /><rect
      x="-0.25"
      y="-0.25"
      width="37.674"
      height="6.696"
      fill="none"
    /></g><g
      transform="translate(4.381) rotate(45)"
      fill="#fff"
      stroke="#fff"
      stroke-width="0.5"
    ><rect
      width="37.174"
      height="6.196"
      stroke="none"
    /><rect
      x="-0.25"
      y="-0.25"
      width="37.674"
      height="6.696"
      fill="none"
    /></g></g></svg>
</template>
