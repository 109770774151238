<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.709"
    height="31.728"
    viewBox="0 0 39.709 31.728"
  >
    <title>Correct</title>
    <path
      d="M1891,596.951l10.809,10.809,23.243-23.243"
      transform="translate(-1888.172 -581.688)"
      fill="none"
      stroke="#304251"
      stroke-width="8"
    /></svg>
</template>
